@import "~antd/dist/antd.css";

.site-layout-main {
  overflow-x: hidden;
}

.site-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  position: fixed;
  z-index: 1;
  width: 100%;
}

.site-layout-sidebar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  margin-top: 64px;
  background: #fff;
}

.site-layout {
  min-height: calc(100vh - 64px);
  margin: 64px 0 0 0;

  &.open {
    margin: 64px 0 0 200px;
  }

  @media (max-width: 767px) {
    transition: all 0.2s;
    margin: 64px 0 0 0;
    &.open {
      transform: translateX(100%);
    }
  }
}

.site-layout-background {
  background: #fff;
}

.site-layout-content {
  margin: 0 24px 24px;
  padding: 16px 24px;
  background: #fff;
}

.bg-white {
  background-color: #fff;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.vh-100 {
  height: 100vh;
}

.vw-100 {
  width: 100vw;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-top: 0;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.position-absolute {
  position: absolute !important;
}

.position-absolute-full {
  @extend .position-absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overflow-auto {
  overflow: auto !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.ratio {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    padding-top: var(--aspect-ratio);
    content: "";
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-1088x170 {
  --aspect-ratio: calc(170 / 1088 * 100%);
}

.exercise {
  font-weight: bold;
}

.search-container {
  position: absolute;
  left: 40px;
  top: -125px;
}

.search {
  padding: 8;
  display: flex;
  justify-content: space-evenly;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
